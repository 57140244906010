@mixin bs-icon($content) {
  content: $content;
  display: inline-block;
  font-family: bootstrap-icons, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
}
