.illustration {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: auto;
  display: inline-block;

  &__poop {
    //background-image: svg-filled($svg-illustration-poop, '#000', quote($icon-dark));
    background-image: svg-url($svg-illustration-poop);
  }

  &__daylight {
    height: rem(22px);
    width: rem(22px);
    background-image: svg-url($svg-illustration-daylight);
    @include themify($themes) {
      background-image: svg-url($svg-illustration-nightlight);
    }
  }

  &__youtube-logo {
    height: rem(57px);
    width: rem(256px);
    background-image: svg-url($svg-illustration-youtube-logo);
    @include themify($themes) {
      background-image: svg-url($svg-illustration-youtube-logo-inverse);
    }
  }


  &--sm {
    height: rem(24px);
    width: rem(24px);
  }

  &--lg {
    height: rem(44px);
    width: rem(44px);
  }

  &--squeezed {
    background-position: left;
    height: 50%;
  }

}


