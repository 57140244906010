.login {
  @include background-base();
  @include themify($themes) {
    background-color: transparent;
    color: themed('heading-color');
  }
  flex: 0 1 auto;
  width: 375px;
  align-self: center;
  background-color: transparent;
  filter: none;
  border: none;
  font-weight: $fw-regular;

  &__heading {
    font-weight: $fw-medium;
    font-size: rem(20px);
    margin-bottom: rem(30px);
  }

  &__logo {
    height: rem(55px);
    margin-bottom: rem(60px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: svg-filled($svg-logo-ftmo, '#000', quote($icon-dark));
    @include themify($themes) {
      background-image: svg-filled($svg-logo-ftmo, '#000', quote(themed('heading-color')));
    }
  }

  &__row {
    display: inline-block;
    width: 100%;
    margin-bottom: rem(10px);
    &--shy {
      display: flex;
      justify-content: space-between;
    }
  }

  &__remember-me {
    font-size: rem(12px);
    font-weight: $fw-regular;
  }

  &__submit {
    margin-top: rem(50px);
    font-size: rem(14px);
    margin-bottom: rem(30px);
    & > *:not(:first-child) {
      margin-top: rem(20px);
    }
  }

  &__input {
    margin-bottom: rem(20px);
    border-radius: rem(10px);
    padding: rem(12px) rem(20px);
    font-size: rem(14px);
  }

  &__submit-via-label {
    font-size: rem(12px);
    font-weight: $fw-semi-bold;
  }

  &__submit-via {
    margin-top: rem(10px);
    margin-bottom: rem(60px);
    & > * {
      margin: 0 rem(15px);
    }
  }
  &__agree {
    text-align: left;
    & > * {
      margin-top: rem(30px);
    }
  }

  & .toast__box {
    margin: 0 0 rem(20px);
  }
}