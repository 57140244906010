.leaderboard {
  &__page {
    & h1, h2, h3, h4, h5, h6 {
      color: $text-dark;
    }

    & p {
      color: $text-mild;
      font-size: rem(16px);
    }

    & .nav-link {
      background-color: #fff;
      padding-top: rem(10px);
      padding-bottom: rem(10px);
    }
  }

  //table
  $leaderboard-padding: rem(30px);
  width: 100%;

  &__wrapper {
    @include background-base();
    width: 100%;
    padding: $leaderboard-padding;
    background-color: #fff;
    border-radius: rem(5px);
    overflow-x: auto;
    & .spinner-box {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: rem(30px) 0;
    }
  }

  &__heading {
    font-size: rem(16px);
    font-weight: $fw-semi-bold;
  }

  &__head {
    border-bottom: 1px solid $wfb-gray;
    @include theme-dark {
      border-color: $table-border-color-dark;
    }
    & th {
      color: $text-mild;
      @include theme-dark {
        color: $text-light;
      }
      font-size: rem(14px);
      font-weight: $fw-medium;
    }

    & th:last-of-type .leaderboard__head-cell {
      text-align: center;
    }
  }

  &__body {
    color: $text-dark;
    @include theme-dark {
      color: $heading-light;
    }

    & td:last-of-type .leaderboard__cell {
      justify-content: center;
    }
  }

  &__head-cell {
    display: block;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0 0 rem(20px) 0;
  }

  &__cell {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: rem(25px) 0;
  }

  &__row {
    border-bottom: 1px solid $wfb-gray;
    @include theme-dark {
      border-color: $table-border-color-dark;
    }
  }

  &__icon {
    & > img {
      height: 100%;
      width: auto;
      text-align: center;
    }

    height: rem(30px);
    width: rem(30px);
    margin-right: rem(15px);

    &--country {
      border-radius: rem(30px);
      overflow: hidden;
    }

    &--winner {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #F4F4F4;
      @include theme-dark {
        border-color: $table-border-color-dark;
      }
      border-radius: rem(5px);

      & > img {
        height: rem(16px);
        width: auto;
      }
    }
  }
}
//mobile
@include media-breakpoint-up(sm) {
  .leaderboard {
    &__cell {
      &--centered {
        justify-content: center;
      }
    }
    &__mobile-header {
      display: none;
    }
  }
}
@include media-breakpoint-down(sm) {
  .leaderboard {
    display: flex;
    flex-flow: column;
    &__mobile-header {
      display: block;
      flex: 1 1 auto;
    }
    &__wrapper {
      background: none;
      border: none;
      padding: 0;
      overflow: visible;
      @include theme-dark() {
        background-color: transparent;
      }
    }
    &__head {
      display: none;
    }
    &__body {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column;
    }
    &__row {
      @include background-base();
      display: flex;
      flex-flow: column;
      margin-bottom: 15px;
      border-bottom: none;
      border-radius: 5px;
      padding: 15px;
    }
    &__cell {
      padding: 5px 0;
      border-bottom: 1px solid #eaeaea;
      @include theme-dark {
        border-color: $table-border-color-dark;
      }
      &--hide-mobile {
        display: none;
        visibility: hidden;
      }
    }
    &__icon {
      &--winner {
        flex: 1 1 100%;
        margin-right: 0;
        border: none;
        &>img {
          height: 20px;
        }
      }

    }
  }
}