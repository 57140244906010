.switch {
  @include switch($switch-height, $switch-width, $switch-slider-spacing);

  &--sm {
    @include switch($switch-height-sm, $switch-width-sm, $switch-slider-spacing-sm);
  }

  &--lg {
    @include switch($switch-height-lg, $switch-width-lg, $switch-slider-spacing-lg);
  }
}

//Background
.switch--primary {
  & input:checked + .slider {
    background-color: $primary !important;
  }
}

.switch--secondary {
  & input:checked + .slider {
    background-color: $secondary !important;
  }
}

.switch--success {
  & input:checked + .slider {
    background-color: $success !important;
  }
}

.switch--danger {
  & input:checked + .slider {
    background-color: $danger !important;
  }
}

.switch--warning {
  & input:checked + .slider {
    background-color: $warning !important;
  }
}

.switch--info {
  & input:checked + .slider {
    background-color: $info !important;
  }
}

.switch--light {
  & input:checked + .slider {
    background-color: $light !important;
  }
}

.switch--dark {
  & input:checked + .slider {
    background-color: $dark !important;
  }
}

//OUTLINE
.switch--outline {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    background-color: transparent;
  }
}

.switch--outline-primary {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    border: $switch-outline-border-width solid $primary;
    background-color: transparent;
  }
}

.switch--outline-secondary {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    border: $switch-outline-border-width solid $secondary;
    background-color: transparent;
  }
}

.switch--outline-success {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    border: $switch-outline-border-width solid $success;
    background-color: transparent;
  }
}

.switch--outline-danger {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    border: $switch-outline-border-width solid $danger;
    background-color: transparent;
  }
}

.switch--outline-warning {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    border: $switch-outline-border-width solid $warning;
    background-color: transparent;
  }
}

.switch--outline-info {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    border: $switch-outline-border-width solid $info;
    background-color: transparent;
  }
}

.switch--outline-light {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    border: $switch-outline-border-width solid $light;
    background-color: transparent;
  }
}

.switch--outline-dark {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: transparent;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    border: $switch-outline-border-width solid $dark;
    background-color: transparent;
  }
}

.switch--outline-mild {
  & .slider {
    border: $switch-outline-border-width solid $switch-slider-border;
    background-color: $body-bg-light;
    @include themify($themes) {
      border: $switch-outline-border-width solid themed('border-color');
      background-color: themed('body-bg-color');
    }

    &:before {
      background-color: $wfb-gray !important;
      @include themify($themes) {
        background-color: themed('dark-gray-bg-color');
      }
    }
  }

  & input:checked + .slider {
    background-color: $body-bg-light;
    @include themify($themes) {
      background-color: themed('body-bg-color');
    }
  }
}

.switch--filled {
  & input:focus + .slider {
    filter: none !important;
  }

  & .slider {
    background-color: $body-bg-light;
    filter: none;

    &:before {
      background-color: $switch-slider-bg !important;
    }
  }

  & input:checked + .slider {
    background-color: $body-bg-light;
  }

  @include theme-dark {
    & .slider {
      background-color: $light-black;

      &:before {
        background-color: $dark-gray !important;
      }
    }

    & input:checked + .slider {
      background-color: $light-black;
    }
  }
}

.switch--colored_circle {
  width: 100px;
  & input:focus + .slider {
    filter: none !important;
  }

  & .slider {
    background-color: $body-bg-light;
    filter: none;
    width: 90px;
    @include themify($themes) {
      background-color: themed('body-bg-color');
    }
    &:before {
      background-color: $switch-slider-bg !important;
      border-width: 2px;
      border-style: solid;
      border-color: $wfb-red;
      width: 45px;
      height: 45px;
      margin: 0;
      @include themify($themes) {
        background-color: $dark-gray !important;
      }
    }
  }

  & input:checked + .slider {
    background-color: $body-bg-light;
    @include themify($themes) {
      background-color: themed('body-bg-color');
    }
    &:before {
      border-color: $wfb-green;
      transform: translateX(45px);
    }
  }

  &.switch--sm {
    width: 90px;
    & .slider {
      width: 80px;
      &:before {
        width: 35px;
        height: 35px;
      }
    }
  }

  &.switch--lg {
    width: 110px;
    & .slider {
      width: 100px;
      &:before {
        width: 50px;
        height: 50px;
      }
    }
    & input:checked + .slider {
      &:before {
        transform: translateX(50px);
      }
    }
  }
}