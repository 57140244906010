//lucian@ftmo.com
//credit https://codepen.io/Kinaly/pen/OMNQpY

@function svg-url($svg){
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: wfb-str-replace($chunk,'"','\'');
    $chunk: wfb-str-replace($chunk,'<','%3C');
    $chunk: wfb-str-replace($chunk,'>','%3E');
    $chunk: wfb-str-replace($chunk,'&','%26');
    $chunk: wfb-str-replace($chunk,'#','%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

@function wfb-str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
            wfb-str-replace(str-slice($string, $index +
            str-length($search)), $search, $replace);
  }
  @return $string;
}

//example usage
//background-image: svg-filled($svg-icon-chevron-down, '#000', quote(themed('input-color')));
//
//param $svg string - haystack
//param $fill-to-replace string - search
//param $new-fill string - replace searched fill
//
//$new-fill needs to be quoted if it comes from variable. See example above.
@function svg-filled($svg, $fill-to-replace, $new-fill) {
  $replaced-svg: wfb-str-replace($svg, $fill-to-replace, $new-fill);
  $replaced-svg-url: svg-url('#{$replaced-svg}');
  @return $replaced-svg-url;
}