.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-items: center;

  &--circle {
    border-radius: 100vh;
    width: rem(45px);
    height: rem(45px);
    padding: rem(2px);
    font-size: 1.5em;
    font-weight: 600;
  }

  &--sm {
    &.badge--circle {
      width: rem(35px);
      height: rem(35px);
      font-size: 1.2em;
    }
  }

  &--lg {
    &.badge--leaderboard {
      border-radius: 5px;
      padding: rem(10px) rem(30px);
      font-size: rem(14px);
      font-weight: $fw-normal;
    }

    &.badge--metrix {
      border-radius: 5px;
      font-size: rem(14px);
      font-weight: $fw-normal;
    }

    &.badge--circle {
      width: rem(50px);
      height: rem(50px);
      font-size: 1.8em;
    }
  }

  &--mild {
    @include background-mild();
  }

  &--milder {
    @include background-milder();
  }

  &--gold-brown {
    @include background-gold-brown();
  }

  &--pink {
    @include background-pink();
  }

  &--code {
    padding: 2px 3px;
    background-color: $code-bg-light;
    border: 1px solid $border-light-main;
    color: $text-dark;
    border-radius: 3px;
    @include themify($themes) {
      color: themed('color-contrast');
    }
  }
}
