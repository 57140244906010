svg.spinner {
  width: $spinner-size + px;
  height: $spinner-size + px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 $spinner-size $spinner-size;

  & .circle {
    &-bg {
      fill: transparent;
      stroke-width: $spinner-width;
      transform-origin: (0.5px * $spinner-size) (0.5px * $spinner-size) 10px;
      transform: rotate(90deg);
    }

    &-main {
      fill: transparent;
      stroke-width: $spinner-width;
      stroke-linecap: round;
      stroke-dasharray: (3.14 * $spinner-inner-size);
      transform-origin: (0.5px * $spinner-size) (0.5px * $spinner-size) 10px;
      animation: spinner $spinner-speed+s linear infinite;
      transform: rotate(90deg);
    }
  }
}

svg.spinner {
  &:not(.spinner--custom) {
    & .circle {
      &-bg {
        stroke: $spinner-bg-stroke;
      }

      &-main {
        stroke: $spinner-main-stroke;
      }
    }
  }
}

@keyframes spinner {
  0% {
    stroke-dashoffset: (3.14 * $spinner-inner-size);
  }
  100% {
    stroke-dashoffset: -(3.14 * $spinner-inner-size);
  }
}

//FTMO spinner
svg.spinner-ftmo {
  width: $spinner-ftmo-size + px;
  height: $spinner-ftmo-size + px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 $spinner-ftmo-size $spinner-ftmo-size;
  background-size: $spinner-ftmo-logo-size + px;
  background-position: center;
  background-repeat: no-repeat;
  $replace-logo-fill: wfb-str-replace($svg-spinner-logo, '#fff', $spinner-ftmo-fill-logo);
  $replace-logo-fill: svg-filled($replace-logo-fill, '#000', $spinner-ftmo-fill);
  background-image: $replace-logo-fill;
  @include themify($themes) {
    font-size: 20px;
    $replace-logo-fill: wfb-str-replace($svg-spinner-logo, '#fff', quote(themed('ftmo-spinner-fill-logo')));
    $replace-logo-fill: svg-filled($replace-logo-fill, '#000', quote(themed('ftmo-spinner-fill')));
    background-image: $replace-logo-fill;
  }
  & .circle {
    &-main {
      fill: transparent;
      stroke-width: $spinner-ftmo-width;
      stroke: $spinner-ftmo-stroke;
      @include themify($themes) {
        stroke: themed('ftmo-spinner-stroke');
      }
      stroke-linecap: round;
      stroke-dasharray: (3.14 * $spinner-ftmo-inner-size);
      transform-origin: (0.5px * $spinner-ftmo-size) (0.5px * $spinner-ftmo-size) 10px;
      animation: spinner-ftmo $spinner-ftmo-speed+s linear infinite;
      transform: rotate(90deg);
    }
    &-orbit {
      x: 50px;
      fill: $spinner-ftmo-stroke;
      @include themify($themes) {
        fill: themed('ftmo-spinner-stroke');
      }
      transform-origin: (0.5px * $spinner-ftmo-size) (0.5px * $spinner-ftmo-size) 10px;
      animation: spinner-ftmo-orbit $spinner-ftmo-speed+s linear infinite;
    }
  }
}

@keyframes spinner-ftmo {
  0% {
    stroke-dashoffset: (3.14 * $spinner-ftmo-inner-size);
  }
  100% {
    stroke-dashoffset: -(3.14 * $spinner-ftmo-inner-size);
  }
}
@keyframes spinner-ftmo-orbit {
  //slight adjustments for imprecise animations
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(358deg);
  }
  100% {
    transform: rotate(716deg);
  }
}

