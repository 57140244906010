.alert {
  border: none;
  font-weight: $fw-semi-bold;
  text-align: center;
  padding: rem(30px);

  & p:last-child {
    margin-bottom: 0;
  }

  @include each-bs-color();

  @include media-breakpoint-down(lg) {
    padding: rem(20px);
  }

  &__icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: rem(35px);
    width: rem(35px);
    $background: wfb-str-replace($svg-icon-challenge-exclamation, '#fff', quote($text-dark));
    background-image: svg-filled($background, '#000', quote($heading-light));
    margin: 0 auto rem(20px);
    @include media-breakpoint-down(lg) {
      margin: 0 auto rem(15px);
    }
  }

  &--primary, &--success, &--danger, &--info, &--dark {
    & .alert__icon {
      background-image: svg-filled($svg-icon-challenge-exclamation, '#000', quote($wfb-yellow));
    }
  }

  &--secondary, &--light {
    & .alert__icon {
      $background: wfb-str-replace($svg-icon-challenge-exclamation, '#fff', quote($text-dark));
      background-image: svg-filled($background, '#000', quote($wfb-yellow));
    }
  }

  &--global {
    margin-bottom: 0;
    border-radius: 0;
    align-items: center;
    z-index: $z-index-global-alert;
    justify-content: center;
    top: 0;
    position: fixed;
    width: 100%;

    &>.container {
      flex-flow: row;
      display: flex;
    }

    @include media-breakpoint-down(lg) {
      &.scroll-hidden {
        display: none;
        visibility: hidden;
      }
    }

    & .alert__icon {
      margin: 0;
      flex: 0 0 auto;
    }

    & .alert__content {
      flex: 1 1 auto;
      display: flex;
      align-self: center;
      flex-flow: column;
      text-align: left;
      padding: 0 15px;
    }

    & .alert__close {
      flex: 0 0 auto;
      box-sizing: content-box;
      width: rem(24px);
      height: rem(24px);
      padding: 0;
      border: 0;
      background-size: contain;
      background-color: transparent;
      background-repeat: no-repeat;
      background-image: svg-filled($svg-icon-close, '#000', quote($heading-light));
      @include themify($themes) {
        background-image: svg-filled($svg-icon-close, '#000', quote(themed('icon-color')));
      }
    }
  }

  $alert-height-xl: 70px;
  $alert-height: 70px;
  $alert-height-xs: 70px;

  &--fixed {



  }

  &--transparency {
    &.alert {
      @include each-bs-color-transparent();

      &--primary, &--success, &--danger, &--info, &--dark, &--secondary, &--light, &--warning {
        & .alert__icon {
          $background: wfb-str-replace($svg-icon-challenge-exclamation, '#fff', '#fff');
          background-image: svg-filled($background, '#000', '#fff');
          background-size: 59%;
          background-position: center;
          border-radius: 5px;
          width: 60px;
          height: 60px;
        }
        & .bi {
          font-size: 35px;
        }
        & .bi, & .fi {
          color: #fff;
          width: 60px;
          height: 60px;
          display: inline-block;
          text-align: center;
          border-radius: 5px;
          margin-bottom: 20px;
          &:before {
            height: 58%;
            width: 58%;
            margin-top: 21%;
          }
        }
        @include themify($themes) {
          color: #fff;
        }
      }

      &--primary .alert__icon {
        $background: wfb-str-replace($svg-icon-thumbs-up, '#fff', '#fff');
        background-image: svg-filled($background, '#000', '#fff');
        background-color: $primary;
      }

      &--success .alert__icon {
        $background: wfb-str-replace($svg-icon-circle-ok, '#fff', '#fff');
        background-image: svg-filled($background, '#000', '#fff');
        background-color: $success;
      }

      &--danger .alert__icon {
        $background: wfb-str-replace($svg-icon-triangle-exclamation-alt, '#fff', '#fff');
        background-image: svg-filled($background, '#000', '#fff');
        background-color: $danger;
      }

      &--info .alert__icon {
        background-color: $info;
      }

      &--dark .alert__icon {
        background-color: $dark;
      }

      &--secondary .alert__icon {
        background-color: $secondary;
      }

      &--light .alert__icon {
        $background: wfb-str-replace($svg-icon-challenge-exclamation, '#fff', '#000');
        background-image: svg-filled($background, '#000', '#000');
        background-color: $light;
      }

      &--warning .alert__icon {
        background-color: $warning;
      }

      &--primary .bi, &--primary .fi {
        background-color: $primary;
      }

      &--success .bi, &--success .fi {
        background-color: $success;
      }

      &--danger .bi, &--danger .fi {
        background-color: $danger;
      }

      &--info .bi, &--info .fi {
        background-color: $info;
      }

      &--dark .bi, &--dark .fi {
        background-color: $dark;
      }

      &--secondary .bi, &--secondary .fi {
        background-color: $secondary;
      }

      &--light .bi, &--light .fi {
        color: #000;
        background-color: $light;
      }

      &--warning .bi, &--warning .fi {
        background-color: $warning;
      }
    }
  }
}

