.account-info-card {
    @include background-base();

    display: flex;
    flex-direction: column;

    padding: 20px;

    &--only-header {
        .account-info-card__detail {
            display: none;
        }
        .account-info-card__actions {
            display: none;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__login {
            display: flex;
            gap: 15px;

            div:nth-child(1) {
                color: #535456;
            }

            div:nth-child(2) {
                font-weight: 500;
                @include themify($themes) {
                    color: themed('color-light');
                }
            }
        }
    }

    &__detail {
        display: flex;
        flex-wrap: wrap;
        column-gap: 35px;
        row-gap: 10px;

        margin-top: 10px;
        margin-bottom: 20px; 

        &__item {
            display: flex;

            div:nth-child(1) {
                color: #A2ACBD;
                font-size: rem(14px);
                margin-right: 5px;
            }
    
            div:nth-child(2) {
                font-size: rem(14px);
                @include themify($themes) {
                    color: themed('color-light');
                }
            }
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 10px;
    }

}