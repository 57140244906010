$radio-heigh-sm: rem(20px);
$radio-heigh-md: rem(24px);
$radio-heigh-lg: rem(30px);

.form-check:not(.form-switch) {
  font-size: rem(12px);
  display: flex;
  align-items: center;

  & .form-check-input {
    cursor: pointer;
    margin-top: 0;
    flex: 0 0 auto;
  }

  & .form-check-label {
    cursor: pointer;
    margin-left: rem(13px);
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  & .form-check-input[type="checkbox"] {
    $checked-color: $wfb-input-bg-dark;
    border-color: $checked-color;
    border-radius: 5px;
    background-size: $checkbox-input-width / 2;

    &:checked {
      color: $checked-color;
      border-color: $checked-color;
      background-image: svg-filled($svg-icon-tick, '#000', quote($checked-color));
    }
  }

  @include themify($themes) {
    & .form-check-input[type="checkbox"] {
      $checked-color: themed('color');
      border-radius: 5px;
      border-color: $checked-color;

      &:checked {
        color: $checked-color;
        border-color: $checked-color;
        background-image: svg-filled($svg-icon-tick, '#000', quote($checked-color));
      }
    }
  }

  & .form-check-input[type="radio"] {
    $checked-color: $wfb-input-bg-dark;
    border-color: $checked-color;
    border-radius: 100px;
    background-size: $checkbox-input-width / 2;

    &:checked {
      color: $checked-color;
      border-color: $checked-color;
      background-image: svg-filled($svg-icon-tick, '#000', quote($checked-color));
    }
  }

  @include themify($themes) {
    & .form-check-input[type="radio"] {
      $checked-color: themed('color');
      border-radius: 100px;
      border-color: $checked-color;

      &:checked {
        color: $checked-color;
        border-color: $checked-color;
        background-image: svg-filled($svg-icon-tick, '#000', quote($checked-color));
      }
    }
  }

  &.form-check {
    min-height: $radio-heigh-md;
    .form-check-input{
      width: $radio-heigh-md;
      height: $radio-heigh-md;
    }
    .form-check-label{
      min-height: $radio-heigh-md;
    }
    &--sm {
      min-height: $radio-heigh-sm;
      .form-check-input{
        width: $radio-heigh-sm;
        height: $radio-heigh-sm;
      }
      .form-check-label{
        min-height: $radio-heigh-sm;
      }
    }
    &--lg {
      min-height: $radio-heigh-lg;
      .form-check-input{
        width: $radio-heigh-lg;
        height: $radio-heigh-lg;
      }
      .form-check-label{
        min-height: $radio-heigh-lg;
      }
    }
  }
}

.form-check {
  &:not(.form-switch) {
    @each $name, $color in $colors {
      &.form-check--#{$name} {
        & .form-check-input:checked {
          //noinspection SassScssUnresolvedPlaceholderSelector
          @extend %#{$name}-base;
          background-color: #{$color};
          border-color: #{$color};
          background-image: svg-filled($svg-icon-tick, '#000', quote($heading-light));
          @include themify($themes) {
            background-image: svg-filled($svg-icon-tick, '#000', quote($heading-light));
          }
        }
      }
    }
  }
}

