.number-input__parent {
  position: relative;
  .number-input__input {
    background-color: #fff;
    border: 2px solid $wfb-input-bg-light;
    text-align: center;
    @include theme-dark {
      background-color: $dark;
      border: 2px solid $border-dark-main;
    }
  }
  .number-input__hidden {
    display: none;
  }
  .number-input__arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: svg-url($svg-icon-chevron-down);
    background-size: 13px;
    background-repeat: no-repeat;
    top: calc(50% - 20px);
    cursor: pointer;
    @include theme-dark {
      background-image: svg-filled($svg-icon-chevron-down, '#000', '#fff');
    }
  }
  .number-input__before {
    left: 2px;
    transform: rotate(90deg);
    background-position: 13px center;
  }
  .number-input__after {
    right: 2px;
    transform: rotate(-90deg);
    background-position: 14px center;
  }
}