.simplebar {
  &-content {
    margin-right: $scroll-bar-track-width;
    margin-bottom: $scroll-bar-track-width;
  }

  &-scrollbar:before {
    transition: opacity $scroll-bar-opacity-transition-speed linear;
    background-color: $scroll-bar-color;
    opacity: $scroll-bar-opacity;
    border-radius: $scroll-bar-radius;
  }
}

.simplebar-scrollbar.simplebar-visible:before {
  background-color: $wfb-blue;
  opacity: $scroll-bar-active-opacity;
}

.simplebar-track.simplebar {
  &-vertical {
    width: $scroll-bar-track-width;
  }

  &-horizontal {
    height: $scroll-bar-track-width;
  }
}
.simplebar__preloader[data-simplebar]:not([data-simplebar="init"]) {
  padding-right: $scroll-bar-track-width !important;
}