@mixin fi-icon() {
  display: inline-block;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.fi {
  display: inline-flex;
  width: rem(26px);
  height: rem(26px);
  &--tooltip {
    display: inline-flex;
    align-items: center;
    align-self: center;
    align-content: center;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    &:before {
      flex: 1 1 auto;
      align-self: center;
    }
  }
  &:before {
    content: "";
    @include fi-icon();
  }

  //icons colored (primary, secondary, ...)
  @each $color, $hex in $colors {
    &--#{$color} {
      @each $name, $icon in $icons {
        &.fi--#{$name} {
          &:before {
            background-image: svg-filled($icon, '#000', quote($hex)) !important;
          }
        }
      }
    }
  }

  //icons
  @each $name, $icon in $icons {
    &--#{$name} {
      &:before {
        background-image: svg-filled($icon, '#000', quote($icon-dark));
        @include themify($themes) {
          background-image: svg-filled($icon, '#000', quote(themed('icon-color')));
        }
      }

      //themed icons
      &.fi--themed {
        &:before {
          background-image: svg-filled($icon, '#000', quote($icon-dark));
          @include themify($themes) {
            background-image: svg-filled($icon, '#000', quote(themed('heading-color')));
          }
        }
      }
    }
  }

  //custom icons
  &--themed {
    &.fi--download {
      &:before {
        background-image: svg-filled($svg-icon-download, '#000', quote($icon-dark));
        @include themify($themes) {
          background-image: svg-filled($svg-icon-download, '#000', quote(themed('heading-color')));
        }
      }
    }
  }

  &--inverted {
    &.fi--binfo {
      &:before {
        $background: wfb-str-replace($svg-icon-challenge-exclamation, '#fff', quote($text-dark));
        background-image: svg-filled($background, '#000', quote($primary));
        @include theme-dark {
          background-image: svg-filled($svg-icon-challenge-exclamation, '#000', quote($primary));
        }
      }
    }
  }
}

//menu
@each $name, $icon in $icons {
  .active .fi--#{$name} {
    &:before {
      background-image: svg-filled($icon, '#000', $wfb-blue);
      @include themify($themes) {
        background-image: svg-filled($icon, '#000', $wfb-blue);
      }
    }
  }
  .tab-switcher__link {
    & .fi--#{$name}:before {
      background-image: svg-filled($icon, '#000', $icon-darker);
      @include themify($themes) {
        background-image: svg-filled($icon, '#000', quote(themed('heading-color')));
      }
    }
    &.active .fi--#{$name}:before {
      background-image: svg-filled($icon, '#000', $heading-light);
    }
  }
  &.notification--sticky .fi--#{$name}:before {
    background-image: svg-filled($icon, '#000', $heading-light);
  }
}

//single specific icons
.fi {
  //nice only one L in dollar :D
  &--themed.fi--dolar:before{
    background-image: svg-filled($svg-icon-dollar, '#000', $icon-dark);
    @include theme-dark {
      background-image: svg-filled($svg-icon-dollar, '#000', $heading-light);
    }
  }
}

