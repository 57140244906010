.transition {
  &--zoom-in {
    transform: scale(0);

    &:not(.zoomed-in) {
      position: absolute;
      opacity: 0 !important;
      z-index: $z-index-below;
      display: block;
      height: 0;
      overflow: hidden;
    }

    &.zoomed-in {
      transition: transform $transition-zoom-in-time ease;
      transform: scale(1);
    }
  }

  &--fade-in {
    transition: opacity 0s;

    &:not(.faded-in) {
      position: absolute;
      opacity: 0 !important;
      z-index: $z-index-below;
      display: block;
      height: 0 !important;
      overflow: hidden;
      min-height: 0 !important;;
      max-height: 0 !important;
    }

    &.faded-in {
      transition: opacity $transition-fade-in-time ease;
      opacity: 1;
    }
  }


  &--zoom-out {
    transition: transform $transition-zoom-in-time ease;
    transform: scale(1);

    &:not(.zoomed-out) {
      opacity: 1;
    }

    &.zoomed-out {
      transform: scale(0);
      position: absolute;
      z-index: $z-index-below;
      opacity: 0 !important;
      height: 0 !important;
      overflow: hidden;
      min-height: 0 !important;;
      max-height: 0 !important;
    }
  }

  &--fade-out {
    transition: opacity $transition-fade-in-time ease;

    &:not(.faded-out) {
      transition: opacity 0s;
      opacity: 1;
    }

    &.faded-out {
      position: absolute;
      z-index: $z-index-below;
      opacity: 0 !important;
    }
  }
}

@include media-breakpoint-down($breakpoint-mobile) {
  &.scroll-hidden {
    display: block;
    height: 0;
    overflow: hidden;
  }
}

.hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.shown {
  display: initial;
  visibility: visible;
  opacity: 1;
}
