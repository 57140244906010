.tab-switcher {
  width: 100%;
  margin: rem(30px) 0;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(auto-fill, minmax($tab-switcher-item-width, 1fr));
  grid-row-gap: rem(15px);
  grid-column-gap: rem(30px);
  &__item {
    display: flex;
    flex: 1 1 auto;
    min-height: rem(65px);
  }
  &__link {
    @include button-reset();
    @include themed-dark-gray-base();
    &:hover,:focus,:active {
      background-color: $wfb-gray;
      @include themify($themes) {
        background-color: themed("dark-gray-bg-color");
      }
    }
    border-radius: 5px;
    flex: 1 1 auto;
    transition: background-color $tab-switcher-transition-speed linear;
    &.active {
      background-color: $primary;
      color: $heading-light;
      &:hover,:focus,:active {
        background-color: $primary;
        @include themify($themes) {
          background-color: $primary;
        }
      }
    }
  }
  &__label {
    display: flex;
    align-items: center;
    //margin-left: rem(20px);
    //margin-right: rem(20px);
    justify-content: center;
    font-size: 14px;
    font-weight: $fw-semi-bold;
    word-break: break-word;
    & .fi {
      margin-right: rem(18px);
      flex: 0 0 auto;
    }
  }
}