.border-bottom {
  &--separator {
    border-color: $table-border-color-light !important;
    @include themify($themes) {
      border-color: themed('table-border-color') !important;
    }
  }
}


.border-top {
  &--separator {
    border-color: $table-border-color-light !important;
    @include themify($themes) {
      border-color: themed('table-border-color') !important;
    }
  }
}


.border-left {
  &--separator {
    border-color: $table-border-color-light !important;
    @include themify($themes) {
      border-color: themed('table-border-color') !important;
    }
  }
}

.border-right {
  &--separator {
    border-color: $table-border-color-light !important;
    @include themify($themes) {
      border-color: themed('table-border-color') !important;
    }
  }
}
