.checkbox {}

// Checkboxes nested inside of alert
.alert {
  &--danger,
  &--dark,
  &--info,
  &--success {
    .form-check:not(.form-switch) {
      .form-check-input[type="checkbox"] {
        border-color: $light-bg-main !important;

        &:checked {
          background-image: svg-filled($svg-icon-tick, '#000', quote($light-bg-main)) !important;
        }
      }
    }
  }

  &--warning,
  &--light,
  &--secondary {
    .form-check:not(.form-switch) {
      .form-check-input[type="checkbox"] {
        border-color: $dark-bg-main !important;

        &:checked {
          background-image: svg-filled($svg-icon-tick, '#000', quote($dark-bg-main)) !important;
        }
      }
    }
  }
}