.page {
  display: flex;
  flex-flow: column;
  padding-top: $page-padding-top;
  flex: 1 1 auto;
  min-width: 0;
  max-width: 100%;

  &--trader {
    position: relative;
    overflow: hidden;
    text-align: center;
    min-height: 100vh;
  }

  &--login {
    padding-top: 0;
    flex-flow: row;
    justify-content: center;
  }
}

[data-container-main] {
  position: relative;
}

.layout-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
}