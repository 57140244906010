.bg {
  &--mild {
    @include themed-mild-bg();
  }
  &--light {
    background-color:$light-bg-main !important;
    @include themify($themes) {
      background-color: themed('mild-bg-color') !important;
    }
  }
  //You see right...
  &--wild {
    background-color:$light-bg-main !important;
    @include themify($themes) {
      background-color: themed('main-bg-color') !important;
    }
  }
  &--base {
    @include background-base();
  }
}