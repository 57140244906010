.btn {
  @include global-size-modifiers;
  min-height: 50px;

  &-sm, &--sm {
    min-height: 44px;
  }

  &--login {
    font-size: rem(14px);
  }

  &--round {
    height: rem(60px);
    width: rem(60px);
    border-radius: 30px;
    padding: 0;
  }

  //specific modifiers
  &--fluid {
    padding-left: rem(10px);
    padding-right: rem(10px);
  }

  &--facebook {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto rem(24px);
    background-color: $facebook-bg;
    background-image: svg-url($svg-logo-facebook);
  }

  &--google {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto rem(24px);
    background-color: $google-bg;
    background-image: svg-url($svg-logo-google);
  }

  &--apple {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto rem(24px);
    background-color: $apple-bg;
    background-image: svg-url($svg-logo-apple);
  }

  &--open {
    box-sizing: content-box;
    width: rem(24px);
    height: rem(24px);
    min-height: initial;
    padding: rem(5px);
    color: #000;
    border: 0;
    opacity: 0.5;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: svg-filled($svg-icon-menu, '#000', quote($icon-dark));
    @include themify($themes) {
      background-image: svg-filled($svg-icon-menu, '#000', quote(themed('icon-color')));
    }
  }

  &--close {
    box-sizing: content-box;
    width: rem(24px);
    height: rem(24px);
    padding: rem(5px);
    color: #000;
    border: 0;
    opacity: 0.5;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: svg-filled($svg-icon-close, '#000', quote($icon-dark));
    @include themify($themes) {
      background-image: svg-filled($svg-icon-close, '#000', quote(themed('icon-color')));
    }
  }

  &--hamburger {
    box-sizing: content-box;
    width: $hamburger-height;
    height: $hamburger-height;
    padding: 0;
    color: #000;
    border: 0;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    border-radius: 0;
    background-image: svg-filled($svg-icon-hamburger, '#000', quote($heading-light));
    @include themify($themes) {
      background-image: svg-filled($svg-icon-hamburger, '#000', quote(themed('icon-color')));
    }
    &.scroll-hidden {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &--gray {
    border: 1px $wfb-gray solid;
    @include themed-gray-base()
  }

  &--mild {
    color: $text-dark;
    background-color: $wfb-gray;
    @include themify($themes) {
      background-color: themed('dark-gray-bg-color');
      color: themed('light-bg-color');
    }
  }

  &--contrast {
    background-color: $wfb-gray;
    color:$dark-bg-main;
    @include themify($themes) {
      background-color: themed('main-bg-color');
      border-color: white;
      color:white;
    }
  }

  &--outline {
    &-gold {
      border: 2px solid $wfb-yellow;
    }
  }

}

//special
[data-clipboard-target] {
  cursor: pointer;
}