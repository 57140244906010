.icon-button {
    display: flex;
    align-items: center;
    font-size: 14px;

    @include themify($themes) {
        color: themed('color-light');
    }

    &__icon {
        border: 2px solid $border-light-main;
        border-radius: 5px;
        padding: 10px;
        margin-right: 15px;
        width: 48px;
        height: 48px;

        @include themify($themes) {
            border: 2px solid themed('border-color');
        }
    }

    &:hover {
        cursor: pointer;
    }
}