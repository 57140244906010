.jumbotron {
  display: block;
  width: 100%;
  padding: rem(110px) 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
  @include media-breakpoint-down(lg) {
    padding: rem(50px) 0;
  }

  &__content {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
  }

  &__text {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;

    & h1 {
      @include media-breakpoint-up(lg) {
        font-size: rem(100px);
      }
    }

    & h2 {
      @include media-breakpoint-up(lg) {
        font-size: rem(60px);
      }
    }
  }

  &__heading {
    font-weight: $fw-normal;
    margin-bottom: rem(50px);
  }

  &__subheading {
    font-size: rem(20px);
    font-weight: $fw-normal;
  }

  &__art {
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      margin-top: rem(20px);
      & > img {
        width: 70%;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-flow: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    & .btn {
      flex: 0 1 0;
      white-space: nowrap;
      margin: 0 rem(7.5px);
    }
    & .btn:first-of-type {
      margin-left: 0;
    }
    & .btn:last-of-type {
      margin-right: 0;
    }
    @include media-breakpoint-down(lg) {
      flex-flow: column;
      & .btn {
        margin: 0;
        &+.btn {
          margin-top: rem(15px);
        }
      }
    }
  }

  //modifiers
  &--dark {
    background: linear-gradient(0.25turn, $jumbotron-gradient-dark-1, $jumbotron-gradient-dark-2) !important;

    & .jumbotron__heading, .jumbotron__text {
      color: $heading-light;
    }

    & .jumbotron__subheading {
      color: $jumbotron-dark-subtext;
    }
  }

  &--blue {
    background: linear-gradient(0.25turn, $jumbotron-gradient-blue-1, $jumbotron-gradient-blue-2) !important;
    color: $heading-light;

    & .jumbotron__content {
      @include media-breakpoint-up(lg) {
        width: 75%;
      }
      @include media-breakpoint-up(xl) {
        width: 60%;
      }
    }

    & .jumbotron__heading {
      font-weight: $fw-semi-bold;
    }

    & .jumbotron__heading, .jumbotron__text {
      color: $heading-light;
    }

    & .jumbotron__subheading {
      color: $heading-light;
    }
  }
}