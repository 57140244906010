html {
    font-size: 16px;// = 1 rem
    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
    font-weight: $fw-normal;
}

body {
    font-family: $font-poppins;
    background-color: $body-bg-light;
    color: $text-dark;
    transition: background-color $global-theme-transition linear;
    @include themify($themes) {
        color: themed('color');
        background-color: themed('body-bg-color');
    }

    @include theme-lang-vi {
        font-family: $font-roboto;
    }
}

h1,h2,h3,h4,h5,h6{
    color: $heading-dark;
    @include themify($themes) {
        color: themed('heading-color');
    }
}

a {
    color: $text-dark;
    @include themify($themes) {
        color: themed('link-color');
    }
}

p {
    line-height: rem(24px);
}

input, select{
    &:disabled {
        opacity: 0.6 !important;
    }
}

*:focus-within {
    outline: none;
}
