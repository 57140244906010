.btn {
  font-weight: $fw-medium;
  &-primary {
    color: $heading-light;

    &:focus, &:active, &:hover {
      color: $heading-light;
    }
  }

  &-danger {
    color: $heading-light;

    &:focus, &:active, &:hover {
      color: $heading-light;
    }
  }

  &-success {
    color: $heading-light;
    &:focus, &:active, &:hover {
      color: $heading-light;
    }
  }

  &-info {
    color: $heading-light;
    &:focus, &:active, &:hover {
      color: $heading-light;
    }
  }

  &-dark {
    background-color: $body-bg-dark;
  }

  &-outline {
    &-secondary {
      color: $text-dark;
      border-color: $text-dark;
      &:hover, &:focus, &:active {
        color: $text-dark;
        border-color: $text-dark;
      }
    }
  }

  @include themify($themes) {
    &-outline {
      &-secondary {
        color: themed('heading-color');
        border-color: themed('heading-color');
        &:hover, &:focus, &:active {
          color: themed('heading-color');
          border-color: themed('heading-color');
          background-color: themed('dark-gray-bg-color');
        }
      }
    }
  }
}

