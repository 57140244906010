// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
//$body-bg: $body-bg-light;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
$primary: $wfb-blue;
$secondary: $wfb-gray;
$success: $wfb-green;
$info: $wfb-teal;
$warning: $wfb-yellow;
$danger: $wfb-red;
$light: $light-bg-main;
$dark: $dark-bg-main;

//badge
$badge-font-weight: $fw-normal;

//form-check
$form-check-input-width: $checkbox-input-width;
$form-check-input-bg: $checkbox-input-bg;

$form-check-input-checked-bg-color: $checkbox-input-checked-bg;

//buttons
$btn-padding-y: 12px;
$btn-padding-x: 70px;
$btn-padding-y-sm: 10px;
$btn-padding-x-sm: 50px;
$btn-padding-y-lg: 12px;
$btn-padding-x-lg: 120px;
$btn-border-radius: 10px;
$btn-border-radius-sm: 5px;
$btn-border-radius-lg: 10px;

$font-weight-base: $fw-normal;

//breadcrumbs
$breadcrumb-divider: "";

//accordion
$accordion-border-radius: $accordion-radius;
$accordion-inner-border-radius: $accordion-radius - rem(2px);
$accordion-button-padding-x: $accordion-padding;
$accordion-button-padding-y: $accordion-padding;

$box-shadow-sm: $shadow-sm;
$box-shadow: $shadow;
$box-shadow-lg: $shadow-lg;
