.accordion {
  @include background-base();
  border: none;
  border-radius: $accordion-radius;
  &-item {
    background-color: transparent;
    &:only-child {
      border: none;
    }
    &:not(:only-child) {
      @include themify($themes) {
        border-color: themed('border-color');
      }
    }
  }
  &-button {
    color: $text-dark;
    font-weight: $fw-normal;
    @include themify($themes) {
      color: themed('heading-color');
      background-color: themed('main-bg-color');
    }
    &::after{
      width: rem(12px);
      height: rem(12px);
      background-size: contain;
      background-position: center;
      background-image: svg-filled($svg-icon-chevron-down, '#000', $text-dark);
      @include themify($themes) {
        background-image: svg-filled($svg-icon-chevron-down, '#000', quote(themed('heading-color')));
      }
    }
    &:not(.collapsed) {
      color: $heading-light;
      background-color: $primary;
      &::after {
        background-image: svg-filled($svg-icon-chevron-down, '#000', $heading-light);
      }
    }
  }
  &-button, &-button:focus, &-button:active, &-button:not(.collapsed) {
    box-shadow: none;
  }
  &-body {
    background-color: transparent;
  }
  &-header {
    border: none;
  }
  &-collapse {
    border: none;
  }
}