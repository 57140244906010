.card {
  &--dashboard {
    text-align: center;

    & .card {
      &-body { //from bs so no BEM
        padding: rem(20px) rem(10px);
      }

      &__heading {
        font-size: rem(40px);
        font-weight: $fw-semi-bold;
      }

      &__text {
        font-size: rem(14px);
        font-weight: $fw-normal;
      }
    }
  }

  //modifiers
  &--ftmo-gradient {
    background: linear-gradient(0.45turn, $jumbotron-gradient-blue-1, $jumbotron-gradient-blue-2) !important;
    color: $heading-light;
    border: none;
  }

  &--outline {
    @each $name, $value in $colors {
      &-#{$name} {
        background-color: $light-bg-main;
        @include theme-dark {
          background-color: $dark-bg-main;
        }
        border: 2px solid;
        border-color: $value;

        & .card {
          &__heading {
            color: $value;
          }
          &__text {
            color: $text-dark;
            @include theme-dark {
              color: $heading-light;
            }
          }
        }
      }
    }
  }

  &--dashboard-stats {
    background-color: $light-bg-main;
    @include theme-dark {
      background-color: $dark-bg-main;
    }
    & .card {
      &-header {
        padding: 0;
        padding-bottom: rem(15px);
        border: none;
        background: none;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
      }

      &__icon {
        height: rem(18px);
      }

      &__heading {
        font-weight: $fw-bold;
        font-size: rem(18px);
        color: $text-dark;
        @include theme-dark {
          color: $heading-light;
        }
      }

      &__text {
        font-size: rem(14px);
        color: $text-mild;
        font-weight: $fw-normal;
        @include theme-dark {
          color: $text-light;
        }
      }
    }
  }

  &--centered {
    & .card {
      &-body {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
