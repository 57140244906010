//naming helper
//if themed => themed-xx
//
//if only one property =>
//  bg => xx-bg
//
//if multiple properties =>
//  bg, color => xx-base
//
//example: themed - bg, text, padding
//  themed-xx-base()


//bg bases
@mixin themed-mild-bg() {
  background-color: $wfb-light-gray;
  @include themify($themes) {
    background-color: themed("mild-bg-color");
  }
}

@mixin themed-dark-gray-bg() {
  background-color: $wfb-gray;
  @include themify($themes) {
    background-color: themed("dark-gray-bg-color");
  }
}

@mixin themed-dark-gray-base() {
  background-color: $wfb-gray;
  color: $text-dark;
  @include themify($themes) {
    background-color: themed("dark-gray-bg-color");
    color: themed("heading-color");
  }
}

@mixin themed-gray-base() {
  background-color: $wfb-gray;
  color: $text-dark;
  border-color: $wfb-gray;
  @include themify($themes) {
    background-color: themed("gray-bg-color");
    color: themed("gray-body-color");
    border-color: themed("gray-border-color");
  }
}

@mixin themed-border-base () {
  border: 2px $wfb-gray solid;
  @include themify($themes) {
    border-color: themed('border-color');
  }
}

//typography
@mixin dark-text-themed() {
  color: $text-dark;
  @include themify($themes) {
    color: themed('heading-color');
  }
}

//extensions
%primary-base {
  color: $heading-light;
  background-color: $primary;
}

%secondary-base {
  color: $text-dark;
  background-color: $secondary;
}

%success-base {
  color: $heading-light;
  background-color: $success;
}

%orange-base {
  color: $heading-light;
  background-color: $wfb-orange;
}

%danger-base {
  color: $heading-light;
  background-color: $danger;
}

%warning-base {
  color: $text-dark;
  background-color: $warning;
}

%info-base {
  color: $heading-light;
  background-color: $info;
}

%light-base {
  color: $text-dark;
  background-color: $light;
}

%dark-base {
  color: $heading-light;
  background-color: $dark;
}

//extensions transparent
%primary-base-transparent {
  color: $text-dark;
  background-color: #{$primary}26;
  border: 3px solid $primary;
}

%secondary-base-transparent {
  color: $text-dark;
  background-color: #{$secondary}26;
  border: 3px solid $secondary;
}

%success-base-transparent {
  color: $text-dark;
  background-color: #{$success}26;
  border: 3px solid $success;
}

%orange-base-transparent {
  color: $text-dark;
  background-color: #{$wfb-orange}26;
  border: 3px solid $wfb-orange;
}

%danger-base-transparent {
  color: $text-dark;
  background-color: #{$danger}26;
  border: 3px solid $danger;
}

%warning-base-transparent {
  color: $text-dark;
  background-color: #{$warning}26;
  border: 3px solid $warning;
}

%info-base-transparent {
  color: $text-dark;
  background-color: #{$info}26;
  border: 3px solid $info;
}

%light-base-transparent {
  color: $text-dark;
  background-color: #{$light}26;
  border: 3px solid $light;
}

%dark-base-transparent {
  color: $text-dark;
  background-color: #{$dark}26;
  border: 3px solid $dark;
}
//special
@mixin button-reset() {
  background: none;
  border: none;
  filter: none;
  &:hover,:focus,:active,:visited {
    background:none;
    border: none;
    filter: none;
  }
}

@mixin link-reset() {
  text-decoration: none;
  &:hover,:focus,:active,:visited {
    color: inherit;
  }
}