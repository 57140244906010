$spacing-top: rem(15px);
$spacing-x-xs: rem(7.5px);
.nav-tabs {
  &--clean {
    border: none;
    & .nav-link {
      padding: 0;
      border-radius: 0;
      border: none;
      color: $text-light;
      background-color: transparent;
      font-size: rem(14px);
      font-weight: $fw-semi-bold;

    }
    & .nav-link.active {
      color: $primary;
      background-color: transparent;
    }
    & .nav-item {
      &+.nav-item {
        margin-left: rem(15px);
      }
    }
  }
}
.nav-pills {
  margin-top: - $spacing-top;
  @include media-breakpoint-down(sm) {
    margin-left: - $spacing-x-xs;
  }

  & .nav-link {
    padding: rem(20px) rem(55px);
    font-size: rem(14px);
    font-weight: $fw-semi-bold;
    background-color: $wfb-gray;
    color: $text-dark;
    border-radius: 5px;
    width: 100%;

    &.active {
      background-color: $wfb-blue;
      color: $heading-light;
    }
  }

  & .nav-item:not(:last-of-type) {
    margin-right: 10px;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }

  & .nav-item {
    margin-top: $spacing-top;
    @include media-breakpoint-down(sm) {
      flex: 1 1 50%;
      padding-right: $spacing-x-xs;
      padding-left: $spacing-x-xs;
    }
  }

  &--themed {
    @include theme-dark {
      & .nav-link:not(.active) {
        background-color: $dark-bg-main;
        color: $heading-light;
      }
    }
  }
}