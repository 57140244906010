.portfolio {

  filter: $filter-drop-shadow-sma;
  background-color: $light-bg-main;
  color: $text-dark;
  border-radius: rem(5px);
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: rem(14px);

  @include themify($themes) {
    color: themed('heading-color');
    background-color: themed('main-bg-color');
  }

  &__head {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    border-bottom: 1px solid $border-light-main;
    padding: 20px;
    @include themify($themes) {
      border-bottom: 1px solid themed('table-border-color');
    }
  }

  &__body {
    background-color: $wfb-light-gray;
    padding: 20px 20px 30px;
    border-radius: 0 0 rem(5px) rem(5px);
    flex: 1 0 auto;
    flex-direction: column;
    display: flex;
    @include themify($themes) {
      background-color: themed('mild-bg-color');
    }
  }

  &__button {
    margin-top: rem(10px);
    color: $light-bg-main;
    background: $wfb-green;
    border-radius: rem(5px);
    text-decoration: none;
    display: block;
    flex: 0 0 auto;
    text-align: center;
    padding-top: rem(6px);
    padding-bottom: rem(6px);
    font-weight: 600;

    &:hover {
      color: $light-bg-main;
      background-color: #26cfbf;
    }
  }

  &__content {
    flex: 1 0 auto;
  }

  &__content_item {
    margin-bottom: rem(20px);
  }

  &__icon {
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    display: inline;
    margin-right: rem(20px);
    border-radius: 25px;
  }

  &__heading {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    word-break: break-word;
  }

  &__link {
    flex: 0 0 auto;
    text-decoration: underline;
    color: $wfb-blue !important;
  }

  &__label {
    font-weight: 600;
    color: $dark-bg-main;
    @include themify($themes) {
      color: themed('link-color');
    }
  }

  &--text-muted {
    color: $text-head-dark;
    @include themify($themes) {
      color: themed('heading-dark');
    }
  }

  &--text-highlite {
    color: $wfb-yellow;
  }

}