@mixin background-base() {
  filter: $filter-drop-shadow-sma;
  background-color: $light-bg-main;
  border: 1px solid $border-light-main;
  border-radius: rem(5px);
  transition: background-color $global-theme-transition, border-color $global-theme-transition;
  @include themify($themes) {
    border: none;
    background-color: themed('main-bg-color');
  }
}