@mixin hidden() {
  display: none;
  visibility: hidden;
  opacity: 0;
}

@mixin visible() {
  display: initial;
  visibility: visible;
  opacity: 1;
}