$notification-margin-y: rem(10px);
$notification-border-thickness: 2px;

.notifications {
  @include background-base();
  z-index: $z-index-navbar-submenu;
  position: absolute;
  display: flex;
  flex-flow: column;
  top: $navbar-el-children-top-pos;
  right: 0;
  width: $navbar-el-children-width;
  //max-height: calc(100vh - #{$navbar-main-real-height});
  &:focus-visible {
    outline: none;
  }

  @include media-breakpoint-down($breakpoint-mobile) {
    width: 100%;
    border: none;
    border-radius: 0;
    min-height: calc(100vh - #{$navbar-main-real-height});
  }

  &__title {
    @include dark-text-themed();
    padding: rem(30px) rem(30px) rem(15px);
    font-size: rem(14px);
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    font-weight: $fw-semi-bold;
  }

  &__mark-all-read {
    text-decoration: underline;
    color: inherit;
    cursor: pointer;
    font-weight: $fw-medium;
  }

  &__area {
    flex: 1 1 auto;
    padding: 0 rem(15px) rem(15px);
    display: block;
    height: 70vh;
    @include media-breakpoint-down(sm) {
      height: auto;
    }

    & > div {
      height: 100%;
      overflow: auto;
    }
  }

  @include media-breakpoint-down($breakpoint-mobile) {
    transition: all 0s !important;
    &.faded-in {
      height: 100vh;
      top: 0 !important;
      overflow: auto;
    }
  }
}

.notification {
  @include themed-border-base();
  position: relative;
  border-radius: rem(5px);
  margin: $notification-margin-y 0;
  text-decoration: none;
  display: flex;
  padding: rem(20px) rem(55px) rem(20px) rem(20px);

  &:hover, :visited {
    color: inherit;
  }

  &__user {
    flex: 0 0 auto;
    margin-right: rem(20px);

    & .fi {
      width: rem(26px);
      height: rem(30px);
    }
  }

  &__recipient {
    color: $primary;
    font-weight: $fw-semi-bold;
  }

  &__preview {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    font-size: rem(14px);
    font-weight: $fw-medium;

    & img {
      max-width: 100%;
    }
  }

  &__text:last-of-type {
    margin-bottom: rem(5px);
  }

  //modifiers
  &--sticky {
    background: $primary;
    border-color: $primary;
    padding-right: rem(20px);

    & .notification__preview, .notification__recipient {
      color: $heading-light;
    }
  }

  &--unread {
    padding-right: rem(20px);

    &:after {
      display: block;
      flex: 0 0 auto;
      align-self: center;
      content: " ";
      background-color: $primary;
      width: rem(15px);
      height: rem(15px);
      border-radius: rem(15px);
      margin-left: rem(20px);
    }

    &.notification--sticky {
      &:after {
        display: block;
        flex: 0 0 auto;
        align-self: center;
        content: " ";
        background-color: $heading-light;
        width: rem(15px);
        height: rem(15px);
        border-radius: rem(15px);
        margin-left: rem(20px);
      }

      &:hover, :focus {
        &:after {
          background-color: $heading-light;
        }
      }
    }
  }

  &--clicked {
    @include themed-dark-gray-bg();
    cursor: auto;
    border-color: $wfb-gray;

    &:hover, :focus {
      border-color: $wfb-gray;
      background-color: $wfb-gray;

      & .notification__recipient {
        color: $primary;
      }

      & .notification__preview {
        color: inherit;
      }

      @include themify($themes) {
        color: themed('heading-color');
        border-color: themed('border-bg-color');
        background-color: themed('border-bg-color');
      }
    }
  }

  &--danger {
    $icon-height: rem(20px);
    margin-top: $notification-margin-y + $icon-height;
    border-color: $wfb-red;
    @include themify($themes) {
      border-color: themed('danger');
    }

    &:before {
      position: absolute;
      top: rem($notification-border-thickness) - ($icon-height/2) - ($notification-margin-y*2);
      content: " ";
      display: block;
      width: 100%;
      height: $icon-height + $notification-margin-y;
      left: 0;
      background-repeat: no-repeat;
      background-position: center $notification-border-thickness;
      background-size: $icon-height auto;
      $icon: wfb-str-replace($svg-icon-triangle-exclamation, '#fff', $text-dark);
      background-image: svg-filled($icon, '#000', $wfb-red);
      @include themify($themes) {
        background-image: svg-filled($svg-icon-triangle-exclamation, '#000', quote(themed('danger')));
      }
    }
  }

  &:hover, :focus {
    background-color: $wfb-gray;
    border-color: $wfb-gray;

    & .notification__recipient {
      color: $primary;
    }

    & .notification__preview {
      color: $text-dark;
      @include themify($themes) {
        border-color: themed('color');
      }
    }

    @include themify($themes) {
      background-color: themed("dark-gray-bg-color");
      border-color: themed("dark-gray-bg-color");
      & .notification__preview {
        color: themed("heading-color");
        //border-color: themed('color');
      }
    }
  }

  //after notification::hover
  &--sticky {
    &:hover, :focus {
      border-color: $primary;
      @include themify($themes) {
        border-color: themed('primary');
      }
    }
  }

  &--danger {
    &:hover, :focus {
      border-color: $wfb-red;
      @include themify($themes) {
        border-color: themed('danger');
      }
    }
  }

  &--marketing {
    &:hover,:focus {
      border-color: $wfb-gray;
      @include themify($themes) {
        border-color: themed("dark-gray-bg-color");
      }
    }
  }

  &--achievement {
    border-color: $wfb-yellow;
    @include theme-dark {
      border-color: $wfb-yellow;
    }
    &:hover,:focus {
      border-color: $wfb-yellow;
      @include theme-dark {
        border-color: $wfb-yellow;
      }
    }
  }
}
