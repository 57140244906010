.bottom-navbar {

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;

  background-color: $dark-bg-main;

  // @include media-breakpoint-up(sm) {
  //   display: none;
  // }


  &__item {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    color: #ffffff;
    font-size: rem(14px);
    text-align: center;

    cursor: pointer;

    padding: 10px;

    @include link-reset();

    &--active {
      background-color: $dark-gray;
    }

    &--disabled {
      color: $text-light;
    }
  }

}