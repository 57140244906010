.vod {
  @include background-base();
  padding: rem(30px) 0;
  &__content {
    padding: 0 rem(30px);
    display: flex;
    align-items: center;
  }

  &__footer {
    font-size: rem(12px);
    font-weight: $fw-normal;
    padding: rem(30px) rem(30px) 0;
    border-top: 1px solid $border-light-main;
    @include themify($themes) {
      border-color: themed('border-color');
    }
  }
}

.vod-item {
  flex: 1 1 auto;
  align-self: flex-end;
  &__label {
    font-weight: $fw-semi-bold;
    margin-bottom: rem(20px);
  }
  &__content {
    margin-bottom: rem(30px);
  }
  &__video {
    border-radius: rem(5px);
    width: 100%;
    height: 250px;
    @include media-breakpoint-down(lg) {
      height: 190px;
    }
  }
}