.grid {
  @for $i from 1 through $max-grid-count {
    &-#{$i} {
      display: grid;
      grid-template-columns: repeat($i, 1fr);
      gap: $base-grid-spacing;
    }
  }

  @each $breakpoint, $value in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up($breakpoint) {
        @for $i from 1 through $max-grid-count {
          &-#{$i} {
            display: grid;
            grid-template-columns: repeat($i, 1fr);
            gap: $base-grid-spacing;
          }
        }
      }
    }
  }
}