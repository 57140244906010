@mixin switch($height, $width, $slider-spacing) {
  $slider-height: $height - $slider-spacing;
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  cursor: pointer;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & input:checked + .slider {
    background-color: $wfb-blue;
  }

  & input:checked + .slider + .slider__item-box {
    & .slider__item {
      opacity: 0;

      &:first-child {
        opacity: 1;
      }
    }
  }

  & input:focus + .slider {
    filter: $filter-drop-shadow-sma;
  }

  & input:checked + .slider:before {
    transform: translateX($width - (2 * $slider-spacing) - $slider-height);
  }

  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $switch-bg;
    transition: $switch-transition-speed;

    display: flex;
    align-items: center;

    &__item-box {
      z-index: $z-index-switch-base;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      user-select: none;
    }

    &__item {
      transition: opacity $switch-transition-speed linear;
      opacity: 1;
      flex: 1 1 auto;
      display: inline;
      text-align: center;
      position: absolute;
      left: auto;
      right: $slider-spacing + $slider-height / 2;

      &:first-child {
        opacity: 0;
        left: $slider-spacing + $slider-height / 2;
        right: auto;
      }
    }

    &--round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }

    &:before {
      z-index: $z-index-switch-slider;
      flex: 0 1 auto;
      content: "";
      height: $slider-height;
      width: $slider-height;
      margin: 0 $slider-spacing;
      background-color: $heading-light;
      transition: $switch-transition-speed;
    }
  }
}
