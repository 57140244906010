//I know nothing better. Who has time to make extra file for each mixin which is used locally?
@mixin quick-action-content-sides-padding() {
  $quick-action-content-sides-padding: 15px;
  padding-left: $quick-action-content-sides-padding;
  padding-right: $quick-action-content-sides-padding;
}

.quick-actions {
  @include background-base();
  z-index: $z-index-navbar-submenu;

  position: absolute;
  top: $navbar-el-children-top-pos;
  right: 0;
  width: $navbar-el-children-width;

  &:focus-visible {
    outline: none;
  }

  @include media-breakpoint-down($breakpoint-mobile) {
    width: 100%;
    border: none;
    border-radius: 0;
    min-height: calc(100vh - #{$navbar-main-real-height});
  }

  &__header {
    @include quick-action-content-sides-padding();
    @include themed-mild-bg();
    padding: rem(30px) rem(50px);
    display: flex;
  }

  &__header-icon {
    flex: 0 0 auto;
  }

  &__header-body {
    margin-left: rem(20px);
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
  }

  &__header-title {
    font-weight: $fw-bold;
  }

  &__header-subtitle {

  }

  &__body {
    @include quick-action-content-sides-padding();
    padding: 0;
  }

  &__footer {
    @include quick-action-content-sides-padding();
    padding: rem(50px) rem(30px);
  }

  &__list {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }

  &__list-item {

  }

  &__item-link {
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: rem(20px) rem(30px);

    &:hover, :active, :focus, :visited {
      text-decoration: none;
      color: inherit;
    }

    &:after {
      flex: 0 0 auto;
      align-self: center;
      @include bs-icon("\f285");
      font-weight: bold;
      transition: transform .1s;
    }
  }

  &__item-icon {
    display: block;
    width: rem(26px);
    height: rem(26px);
    flex: 0 0 auto;
    margin: 0 rem(25px) 0 0;

    &:before {
      background-position: center;
      background-size: cover;
      display: block;
      width: auto;
      height: 100%;
    }
  }

  &__item-body {
    flex: 1 1 auto;
  }

  &__item-title {
    font-weight: $font-weight-bold;
  }

  &__item-subtitle {

  }

  @include media-breakpoint-down($breakpoint-mobile) {
    transition: all 0s !important;
    &.faded-in {
      top: 0 !important;
      height: 100vh;
      overflow: auto;
    }
  }
}
