.navbar {
  height: $navbar-main-real-height;
  &--fixed {
    height: $navbar-main-real-height;
    position: fixed;
    top: 0;
    z-index: $z-index-navbar-fixed;
    width: 100%;
    &+[data-container-main] {
      position: relative;
      top: $navbar-main-real-height;
      @include media-breakpoint-down($breakpoint-mobile) {
        &.scroll-hidden {
          position: initial;
          top: 0;
        }
      }
    }
  }
  &__links {
    &--main {
      justify-content: flex-end;
    }
  }

  &__brand {

  }
  &--main {
    padding-top: $navbar-main-p-top;
    padding-bottom: 0;
    background-color: $dark-bg-main;
    &.scroll-hidden {
      @include media-breakpoint-down($breakpoint-mobile) {
        top: 0 !important;
        overflow: visible;
        > .container {
          max-width: 100%;
        }
      }
    }
    & .navbar-link {
      color: $heading-light;

      &:hover, &:focus, &:visited, &:active {
        color: $heading-light;
      }
    }
  }
}

.brand {
  &__image {
    display: block;
    height: $navbar-el-height;
    width: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: svg-filled($svg-logo-ftmo, '#000', quote($heading-light));
    &--xmas {
      background-image: svg-filled($svg-logo-ftmo-xmas, '#000', quote($heading-light));
    }
  }

  &__name {
    color: $text-light;
  }
}
.nav-item {
  align-self: center;
}

.top-bar {
  &__close {
    display: block;
    padding: 0;
    border-radius: 0;
    min-height: auto;
    @include media-breakpoint-up($breakpoint-mobile) {
      display: none;
    }
    &--quick-actions {
      flex: 0 0 auto;
      margin-right: rem(-15px);
    }
    &--notifications {
      flex: 0 0 auto;
      margin-left: auto;
      margin-top: 15px;
      margin-right: rem(30px);
    }
  }
}

.navbar-link {
  text-decoration: none;
  color: $text-dark;
  margin-left: 15px;

  &:hover, &:focus {
    color: $text-dark;
  }

  &__image {
    display: block;
    height: $navbar-el-height;
    width: $navbar-el-height;
    background-attachment: initial;
    background-position: center;
    background-size: cover;

    &--profile {
      border-radius: 100px;
      margin-right: 15px;
    }
  }

  &__notifications {
    display: flex;
    position: relative;
    font-size: 22px;
    margin-left: 30px;

    @include media-breakpoint-down(sm) {
      margin-left: 15px;
    }

    &:not(.active) {
      & .navbar-link__notifications-text-indicator {
        display: none;
        height: 0;
        z-index: -100;
        visibility: hidden;
      }
    }

    &.active {
      & .navbar-link__notifications-indicator {
        content: "";
        position: absolute;
        top: -2px;
        left: 16px;
        width: 10px;
        height: 10px;
        background-color: $primary;
        background-clip: padding-box;
        border-radius: 50%;
      }
      & .navbar-link__notifications-text-indicator {
        $size: 15px;
        position: absolute;
        text-align: center;
        font-size: rem(10px);
        top: -($size * 0.3);
        left: $size + 1;
        z-index: $z-index-navbar-notifications-indicator;
        width: $size;
        height: $size;
        color: $heading-light;
        background-color: $danger;
        background-clip: padding-box;
        border-radius: 50%;
      }
    }
  }
}

//sorry :/
.hamburgerized-nav {
  padding: 0 20px;
  @include media-breakpoint-down(md) {
    //on approx 578px is still fucked
    padding-left: $hamburger-height;
  }

  @include media-breakpoint-down(sm) {
    padding-left: $hamburger-left + $hamburger-height + $hamburgerized-lf-padding-sm;
    padding-right: $hamburgerized-lf-padding-sm;
  }
}
