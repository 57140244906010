@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    @if $theme != lang-vi {
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }

        @content;
        $theme-map: null !global;
      }
    }
  }
}

@mixin theme-dark() {
  .theme-dark & {
    @content;
  }
}

@mixin theme-lang-vi() {
  .theme-lang-vi & {
    @content;
  }
}


