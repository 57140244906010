*:focus-visible {
  outline: none;
}

@import 'bootstrap/col';
@import 'bootstrap/accordion';
@import 'bootstrap/buttons';
@import 'bootstrap/breadcrumbs';
@import 'bootstrap/dropdown';
@import 'bootstrap/modal';
@import 'bootstrap/form-check';
@import 'bootstrap/form-control';
@import 'bootstrap/form-select';
@import 'bootstrap/toast';
@import 'bootstrap/nav';
@import 'bootstrap/alert';
@import 'bootstrap/tooltip';
