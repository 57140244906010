.challenge-box {

  @include background-base();

  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding: rem(20px);
  height: 100%;
  align-items: center;
  align-content: center;

  @include themify($themes) {
    color: themed('link-color');
  }

  &__header {
    flex: 0 1 auto;
    padding: rem(30px) 0;
    display: flex;
  }

  &__header-icon {
    color: $icon-dark;
    font-size: rem(40px);
    height: rem(40px) !important;
    width: rem(40px) !important;
    font-weight: $fw-bold;
    @include themify($themes) {
      color: themed('icon-color');
    }
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-flow: column;
  }

  &__footer {
    flex: 0 1 auto;
    padding: rem(30px) 0;
    display: flex;
    align-items: center;
  }

  &__features {
    flex: 0 1 auto;
    display: flex;
    flex-flow: column;
    list-style-type: none;
    padding-left: 0;
    padding-top: rem(30px);
    margin-bottom: 0;

    @include media-breakpoint-up(xl) {
      padding-left: rem(40px);
      padding-right: rem(40px);
    }

  }

  &__feature {
    display: flex;
    flex: 1 1 100%;
    padding: rem(10px) 0;
    &:before {
      font-size: rem(24px);
      @include bs-icon("\f26b");
      margin-right: rem(15px);
      color: $wfb-green;
    }
  }

  &__heading {
    padding-bottom: rem(10px);
    text-transform: uppercase;
    font-weight: $fw-semi-bold;
    font-size: rem(40px);
    text-align: center;
  }

  &__subheading {
    font-weight: $fw-medium;
    font-size: rem(16px);
    text-align: center;
    padding-bottom: rem(15px);
  }

  &__description {
    font-weight: $fw-semi-bold;
    font-size: rem(14px);
    text-align: center;
    padding: rem(15px) 0;

    & p:last-child {
      margin-bottom: 0;
    }
  }
}

//WIDE
.challenge-box--wide {
  @include themify($themes) {
    background-color: $primary;
  }
  width: 100%;
  background-color: $wfb-blue;
  color: $heading-light;

  & .challenge-box__heading {
    font-size: rem(25px);
    font-weight: $fw-semi-bold;
  }

  & .challenge-box__header {
    padding-top: rem(10px);
  }

  & .challenge-box__footer {
    padding-bottom: rem(20px);
  }

  & .challenge-box__subheading {
    font-size: rem(20px);
    padding-bottom: 0;
    padding-top: rem(10px);
  }

  & .challenge-box__features {
    padding-top: rem(20px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: rem(30px);
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }

  & .challenge-box__header-icon {
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: svg-filled($svg-icon-challenge-exclamation, '#000', quote($wfb-yellow));
  }
}