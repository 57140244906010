.select {
  @include themed-dark-gray-base;
  cursor: pointer;
  padding: 0;

  &[disabled] {
    cursor: default;
    opacity: 0.6;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__input {
    padding: rem(10px) rem(20px);
    position: relative;

    &::before {
      transition: .1s transform linear;
      content: '';
      display: block;
      height: 100%;
      top: 0;
      right: rem(20px);
      width: 11px;
      position: absolute;
      -webkit-background-size: 0.6875rem auto;
      background-size: 0.6875rem auto;
      background-repeat: no-repeat;
      background-position: center;
      background-image: svg-filled($svg-icon-chevron-down, '#000', $wfb-input-text-dark);
      @include theme-dark() {
        background-image: svg-filled($svg-icon-chevron-down, '#000', $heading-light);
      }
    }

  }

  &__options {
    height: 0;
    overflow: hidden;
    user-select: none;
  }

  &__option {
    padding: rem(5px) rem(20px);

    &:first-of-type {
      border-top: 1px solid $table-border-color-light;
      @include theme-dark {
        border-top: 1px solid $border-dark-main;
      }
    }

    &:hover {
      background-color: $wfb-blue;
    }

    &[disabled] {
      cursor: default;
      color: #AAAAAA;

      &:hover {
        background-color: inherit;
      }
    }
  }

  &--open .select {
    &__input {
      &::before {
        transform: rotate(180deg);
      }
    }

    &__options {
      height: auto;
      overflow: visible;
      visibility: visible;
    }
  }

  &--animated {
    & .select__options {
      height: auto;
      max-height: 0;
      transition: max-height $global-theme-transition ease-in-out;
    }

    &.select--open {
      & .select__options {
        height: auto;
        max-height: 1000px;
      }
    }
  }

  &--floating {
    position: relative;

    & .select__options {
      position: absolute;
      background-color: inherit;
      width: 100%;
      z-index: $z-index-above;
    }
  }
}
