.text {
  //transform
  &--upper {
    text-transform: uppercase;
  }

  &--lower {
    text-transform: lowercase;
  }

  &--cap {
    text-transform: capitalize;
  }

  //style
  &--italic {
    font-style: italic;
  }

  //weight
  &--thin {
    font-weight: $fw-thin;
  }

  &--semi-thin {
    font-weight: $fw-light;
  }

  &--regular {
    font-weight: $fw-regular;
  }

  &--normal {
    font-weight: $fw-normal;
  }

  &--medium {
    font-weight: $fw-medium;
  }

  &--semi-bold {
    font-weight: $fw-semi-bold;
  }

  &--bold {
    font-weight: $fw-bold;
  }

  &--extra-bold {
    font-weight: $fw-extra-bold;
  }

  //colors one of the few exceptions for !important
  &--primary {
    color: $primary !important;
  }

  &--secondary {
    color: $secondary !important;
  }

  &--success {
    color: $success !important;
  }

  &--danger {
    color: $danger !important;
  }

  &--info {
    color: $info !important;
  }

  &--warning {
    color: $warning !important;
  }

  &--dark {
    color: $dark !important;
  }

  &--light {
    color: $light !important;
  }

  &--gray {
    color: $light !important;
  }

  &--mild {
    color: $text-mild !important;
    @include themify($themes) {
      color: themed('text-mild') !important;
    }
  }

  &--contrast {
    color: $text-dark !important;
    @include themify($themes) {
      color: themed('heading-color') !important;
    }
  }

  //size
  &--sm {
    font-size: rem(12px);
  }

  //text-normal is already taken
  &--casual {
    font-size: rem(14px);
  }

  &--lg {
    font-size: rem(16px);
  }

  //custom links
  &--link {
    color: $primary;
    text-decoration: underline;
  }

}