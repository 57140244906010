.table {
  line-height: $tables-line-height;
  @include themify($themes) {
    color: themed('heading-color');
  }


  & thead {
  }

  & tbody {
  }


  &--mild {
    & tbody, thead, tfoot, tr, td, th {
      border-color: $table-border-color-light !important;
      @include themify($themes) {
        border-color: themed('border-color') !important;
      }
    }

    & thead {
      color: $text-head-light;
      @include themify($themes) {
          color: themed('table-head-color');
      }
    }

    & tbody {
      @include themed-mild-bg();
      @include themify($themes) {
        color: themed('heading-color');
      }
    }
  }
}