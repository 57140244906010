// FTMO variables

//fonts
$font-poppins: 'Poppins', sans-serif;
$font-roboto: 'Roboto', sans-serif;

//native
$html-font-size: 16px; // = 1 rem

//bootstrap crossover helper variables
$bs-gutter-x: rem(24px) / 2;
$bs-gutter-y: 0;

//breakpoints
$breakpoint-mobile: lg;

//z-index
$z-index-tooltip: 2100;
$z-index-modal: 2000;
$z-index-global-alert: 1100;
$z-index-toast: 1200;
$z-index-navbar-submenu: 900;
$z-index-navbar-fixed: 500;
$z-index-navbar-notifications-indicator: 2;
$z-index-header: 1000;
$z-index-switch-base: 149;
$z-index-switch-slider: 150;
$z-index-sidebar: 1000;

$z-index-above: 500;
$z-index-below: -100;

//accordion
$accordion-radius: rem(5px);
$accordion-padding: rem(20px);

//transitions
$menu-border-transition: 0.15s;
$global-theme-transition: 0.3s;
$transition-zoom-in-time: 0.3s;
$transition-fade-in-time: 0.5s;

//page
$page-padding-top: rem(20px);

//main colors
$wfb-blue: #0781FE;
$wfb-light-blue: #ADE7FF;
$wfb-green: #00C7B4;
$wfb-gold: #FFDA46;
$wfb-brown-gold: #35312F;
$wfb-light-green: #C3FCF2;
$wfb-red: #FF3548;
$wfb-gray: #EAEAEA;
$wfb-light-gray: #F9F9F9;
$wfb-dark-gray: #292A2D;
$wfb-yellow: #FFC700;
$wfb-teal: #0DCAF0;
$wfb-orange: #FFA500;
$dark-bg-main: #262729;
$light-bg-main: #ffffff;
$separator-color: #dee2e6;
$border-light-main: #dee2e6;
$border-dark-main: #36373B;
$icon-light: #A2ACBD;
$icon-dark: #555B6C;
$icon-darker: #262729;
$code-bg-light: #f8f9fa;
$code-bg-dark: #000;
$nav-link-dark: #262729;
$dark-gray: #36373B;
$light-black: #1E1E1E;

//jumbotron
$jumbotron-dark-subtext: #A2ACBD;
$jumbotron-gradient-dark-1: #1E1E1E;
$jumbotron-gradient-dark-2: #262729;
$jumbotron-gradient-blue-1: $wfb-blue;
$jumbotron-gradient-blue-2: #6972EA;


//tables
$tables-line-height: 3rem;
$table-border-color-dark: #343638;
$table-border-color-light: #EAEAEA;

$text-head-light: #c6c6c6;
$text-head-dark: #535456;

//switch
$switch-width: 110px;
$switch-height: 45px;
$switch-slider-spacing: 13px;

$switch-width-sm: 100px;
$switch-height-sm: 35px;
$switch-slider-spacing-sm: 13px;

$switch-width-lg: 130px;
$switch-height-lg: 50px;
$switch-slider-spacing-lg: 15px;

$switch-bg: #ccc;
$switch-slider-bg: #C6C6C6;
$switch-slider-border: #EAEAEA;
$switch-outline-border-width: 2px;
$switch-transition-speed: 0.15s;

//headings
$heading-light: #fff;
$heading-dark: #000;

//main text
$wfb-font-family-base: $font-poppins;
$text-light: #A2ACBD;
$text-dark: #262729;
$text-disabled-dark: #C6C6C6;
$text-disabled-light: #535456;
$text-mild: #555B6C;

//main bg
$body-bg-light: #F4F4F4;
$body-bg-dark: #1E1E1E;

//spinners
$spinner-main-stroke: #0781FE;
$spinner-bg-stroke: #535456;

$spinner-ftmo-stroke: $dark-bg-main;
$spinner-ftmo-fill: $dark-bg-main;
$spinner-ftmo-fill-logo: $icon-dark;

$spinner-size: 24;
$spinner-inner-size: 20;
$spinner-width: 3;
$spinner-speed: 1.2;

$spinner-ftmo-size: 190;
$spinner-ftmo-inner-size: 170;
$spinner-ftmo-width: 3;
$spinner-ftmo-speed: 2;
$spinner-ftmo-logo-size: 145;

//tab-switcher
$tab-switcher-transition-speed: 0.15s;
$tab-switcher-item-width: rem(200px);

//forms
$wfb-input-bg-dark: #36373B;
$wfb-input-bg-light: #EAEAEA;
$wfb-input-border-dark: #36373B;
$wfb-input-border-light: #36373B;
$wfb-input-text-dark: $text-dark;
$wfb-input-text-light: $heading-light;
$wfb-input-bg-dark-hover: #2c2c2c;

//socials
$facebook-bg: #1447A0;
$google-bg: #fff;
$apple-bg: #fff;

//navbar
$navbar-height: 75px;
$navbar-el-height: 35px;

$navbar-el-children-top-pos: $navbar-el-height + ($navbar-height - $navbar-el-height) / 2;
$navbar-el-children-width: 435px;

//navbar--main
$navbar-main-p-top: 0px; //minh said no padding top
$navbar-main-real-height: $navbar-height + $navbar-main-p-top;

//sidebar--main
$sidebar-width: 270px;
$sidebar-margin-y: 20px;
$sidebar-margin-x: 15px;
$sidebar-padding-y: 0px;
$sidebar-padding-x: 0px;
$sidebar-real-width: $sidebar-width + $sidebar-padding-x * 2 + $sidebar-margin-x * 2;

//hamburger sidebar
$hamburger-height: 22px;
$hamburger-left: 30px;
$sidebar-hamburger-top: ($navbar-height / 2) - ($hamburger-height / 2);

//hamburgerized
$hamburgerized-lf-padding-sm: 2px;

//sidebar

//other
$shadow-sm:0px 0px 10px rgba(0, 0, 0, 0.1);
$shadow:0px 0px 15px rgba(0, 0, 0, 0.1);
$shadow-lg:0px 0px 20px rgba(0, 0, 0, 0.1);
$filter-drop-shadow: drop-shadow($shadow);
$filter-drop-shadow-sma: drop-shadow($shadow-sm);

//check-box & radios
$checkbox-border-dark: #555B6C;
$checkbox-border-light: #fff;
$checkbox-input-width: em(32px);
$checkbox-input-bg: transparent;

$checkbox-input-checked-bg: transparent;

//HELPERS
//helpers-font-weight
//because figma.com uses keywords and I am lazy
$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-normal: 500;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-demi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 900;

//portlets padding
$portlets-x-padding: rem(20px);


//ftmo scrollbar
$scroll-bar-radius: 10px;
$scroll-bar-track-width: 9px;
$scroll-bar-color: $wfb-blue;
$scroll-bar-opacity: 0.7;
$scroll-bar-active-opacity: 1;
$scroll-bar-opacity-transition-speed: 0.1s;

//grid
$max-grid-count: 12; //min 1
$base-grid-spacing: rem(15px)
