.portlet {
  @include background-base();
  border: none;
  padding: rem(30px) $portlets-x-padding;
  font-size: rem(14px);
  text-align: left;
  display: flex;
  flex-flow: column;
  flex: 0 1 auto;

  &--slim {
    padding: rem(30px) $bs-gutter-x;
  }

  &__header {
    flex: 0 1 auto;
    font-weight: $fw-semi-bold;
    font-size: rem(16px);
    display: flex;

    @include themify($themes) {
      color: themed('heading-color');
    }

    & i {
      flex: 0 0 auto;
    }

    & > * {
      flex: 0 1 auto;
    }

    &--separated {
      padding-bottom: rem(20px);
      border-bottom: 1px solid $border-color;
      margin-left: -$portlets-x-padding;
      margin-right: -$portlets-x-padding;
      padding-left: $portlets-x-padding;
      padding-right: $portlets-x-padding;
      @include themify($themes) {
        border-color: themed('border-color');
      }
    }

    &--gutterless {
      margin-left: $portlets-x-padding;
      margin-right: $portlets-x-padding;
    }

    &--slim {
      margin-left: -$bs-gutter-x;
      margin-right: -$bs-gutter-x;
      padding-left: $bs-gutter-x;
      padding-right: $bs-gutter-x;
    }
  }

  &__content-item {
    margin-left: $portlets-x-padding;
    margin-right: $portlets-x-padding;
  }

  &__body {
    flex: 0 1 auto;
    padding-top: rem(20px);

    &--gutterless {
      margin-left: $portlets-x-padding;
      margin-right: $portlets-x-padding;
    }

  }

  &__footer {
    flex: 0 1 auto;

    &--separated {
      margin-left: -$portlets-x-padding;
      margin-right: -$portlets-x-padding;
      padding-left: $portlets-x-padding;
      padding-right: $portlets-x-padding;
      border-top: 1px solid $border-color;
      padding-top: rem(20px);
      @include themify($themes) {
        border-color: themed('border-color');
      }
    }

    &--slim {
      margin-left: -$bs-gutter-x;
      margin-right: -$bs-gutter-x;
      padding-left: $bs-gutter-x;
      padding-right: $bs-gutter-x;
    }

  }

  &--no-gutter {
    padding: rem(30px) 0;
    overflow: hidden;
  }
}