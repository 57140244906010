$active-border-thickness: 3px;
$active-border-thickness-child: 2px;
$active-border-color: $wfb-blue;
$active-border-right: $active-border-thickness $active-border-color solid;

$link-py: 20px;
$link-px: 30px;

.sidebar {
  display: block;

  &__brand-image {
    display: block;
    height: rem(35px);
    width: rem(150px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: svg-filled($svg-logo-ftmo, '#000', quote($icon-dark));
    @include theme-dark {
      background-image: svg-filled($svg-logo-ftmo, '#000', quote($heading-light));
    }

    &--xmas {
      background-image: svg-filled($svg-logo-ftmo-xmas, '#000', quote($icon-dark));
      @include theme-dark {
        background-image: svg-filled($svg-logo-ftmo-xmas, '#000', quote($heading-light));
      }
    }
  }

  &__content {
    padding: 0;
    filter: $filter-drop-shadow;
    @include media-breakpoint-down(lg) {
      padding-top: 50px;
    }
  }

  &__links {
    padding: 0;
    margin: 0;
  }

  &__link {
    padding: $link-py ($link-px - $active-border-thickness) $link-py $link-px;
    //not used but lets keep it here until Sept 21
    //border-right: $active-border-thickness transparent solid;
    //transition: border-right-color $menu-border-transition linear;
    width: 100%;
    height: 100%;
    overflow: visible;
    font-size: 12px;
    font-weight: 500;
    color: $nav-link-dark;
    text-decoration: none;
    cursor: pointer;

    display: flex;
    flex-flow: row;

    &:hover, &:focus, &:active, &:visited {
      color: $nav-link-dark;
      text-decoration: none;
    }

    &.active {
      //not used but lets keep it here until Sept 21
      //border-right: $active-border-right;

      & .icon-link__icon {
        color: $wfb-blue;
      }

      @include themify($themes) {
        & .icon-link__text {
          color: themed('heading-color');
        }
      }
    }

    //chevron logic for collapse links
    &[data-bs-toggle='collapse'] {
      &:not(.collapsed) {
        & .icon-link__icon {
          color: $wfb-blue;
        }
      }

      &:after {
        flex: 0 0 0;
        align-self: center;
        @include bs-icon("\f282");
        font-weight: bold;
        transform: rotate(-180deg);
        transition: transform .1s;
      }

      &.collapsed {
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }

  //end sidebar__link

  &__item {
    list-style-type: none;
    user-select: none;
  }

  &__collapse {
    transition: height .1s ease;
  }

  &__separator {
    list-style-type: none;
    border-top: 1px solid $separator-color;

    @include themify($themes) {
      border-color: themed('table-border-color');
    }

  }

  &__heading {
    font-size: 16px;
    font-weight: 600;
    padding: 20px 30px;
    color: $heading-dark;
  }

  //open close buttons
  &__close {
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    padding: 0;
    border-radius: 0;
    min-height: auto;
  }

  &__select-wrapper {
    flex: 1 1 100%;
    display: flex;
    position: relative;

    &:after {
      @include bs-icon("\f282");
      flex: 0 0 0;
      position: absolute;
      right: 0;
      align-self: center;
      font-weight: bold;
      transition: transform .1s;
    }
  }

  &__select {
    flex: 1 1 100%;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    appearance: none;
    cursor: pointer;
    color: inherit;
    user-select: none;

    &:active, :focus {
      background: transparent;
      border: none;
      outline: none;
    }
  }

  &__open {
    z-index: $z-index-sidebar;
    position: absolute;
    top: rem(20px);
    left: rem(20px);
    width: rem(24px);
    height: rem(24px);
    @include background-base();
  }
}

//end .sidebar

.icon-link {
  flex: 1 0 0;
  align-self: center;
  display: flex;
  height: 25px;
  width: 100%;
  flex-flow: row;

  &__icon {
    transition: color .3s;
    font-size: 22px;
    max-height: 22px;
    margin-right: rem(25px);
    align-self: center;
    color: $icon-dark;
    display: inline-flex;

    &--sm {
      font-size: 7px;
    }

    @include themify($themes) {
      color: themed('icon-color');
    }
  }

  &__text {
    flex: 1 1 100%;
    align-self: center;
  }
}

.collapse {
  &__links {
    list-style-type: none;

    & .sidebar__link {
      border: none;
    }
  }

  &__item {
    & .sidebar__link {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

//hamburger
.sidebar-hamburger {
  z-index: $z-index-sidebar;
  display: block;
  position: fixed;
  height: 20px;
  min-height: 20px;
  top: $sidebar-hamburger-top;
  left: $hamburger-left;
  @include media-breakpoint-up(lg) {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

//--main
.sidebar--main {
  width: $sidebar-width;
  margin: $sidebar-margin-y $sidebar-margin-x;
  padding: $sidebar-padding-y $sidebar-padding-x;

  &.shown {
    @include media-breakpoint-down(lg) {
      top: 0 !important;
    }
  }

  & .sidebar__content {
    @include background-base();
  }

  @include media-breakpoint-up(lg) {
    position: fixed;
    flex: 0 0 auto;
    top: $navbar-main-real-height;
    bottom: 0;
    &.sticky {
      top: 0;
    }
    & + .page {
      margin-left: $sidebar-real-width;
    }
    & .sidebar {
      &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      &__links {
        bottom: 0;
        position: absolute;
        display: block;
        top: 0;
        width: 100%;

        &-wrapper {
          position: relative;
          height: 100%;
          flex: 1 1 auto;
        }
      }
    }

    & .sidebar__close {
      @include hidden();
    }
  }

  @include media-breakpoint-down(lg) {
    & .sidebar__content {
      border-radius: 0 !important;
      min-height: 100vh;
    }

    display: none;
    visibility: hidden;
    opacity: 0;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    min-height: 100%;
    z-index: $z-index-sidebar;
  }


  @include themify($themes) {
    & .sidebar {
      &__link {
        color: themed('color');
      }

      &__heading {
        color: themed('heading-color');
      }
    }
  }
}

//collapse
.sidebar--collapse {
  min-height: 100vh;
  z-index: $z-index-sidebar;
  @include media-breakpoint-down(sm) {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  & .sidebar {
    &__content {
      @include background-base();
      border-radius: 0;
      min-height: 100%;
    }
  }

  @include themify($themes) {
    & .sidebar {
      &__link {
        color: themed('color');
      }

      &__heading {
        color: themed('heading-color');
      }
    }
  }
}

//border right for collapse links
//not used but lets keep it here until Sept 21
//.sidebar__link:not(.collapsed)[data-bs-toggle="collapse"] {
//  border-right: $active-border-right;
//}

//page interaction
.sidebar {
  &--main {

  }
}
