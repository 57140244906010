.breadcrumb {
  margin: 0;
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
  }
  &-item {
    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    margin-bottom: 1rem;
    font-size: rem(14px);
    font-weight: $fw-semi-bold;
    color: $text-dark;
    @include themify($themes) {
      color: themed('link-color');
    }

    & + .breadcrumb-item {
      padding-left: rem(15px);
      padding-right: rem(15px);
      &:last-of-type {
        padding-right: 0;
      }
    }

    &.active, &.active > * {
      font-weight: $fw-medium;
      color: $text-disabled-dark;
      @include themify($themes) {
        color: themed('color-disabled');
      }
    }

    & > a {
      text-decoration: none;
    }

    &:first-of-type {
      padding-left: 0;
      &::after {
        content: "";
        height: rem(10px);
        width: rem(12px);
        display: inline-block;
        margin-left: rem(15px);
        background-size: cover;
        background-repeat: no-repeat;
        background-image: svg-filled($svg-icon-home, '#000', $text-disabled-dark);
        @include themify($themes) {
          background-image: svg-filled($svg-icon-home, '#000', quote(themed('color-disabled')));
        }
      }
    }
  }
}