@mixin background-mild() {
  //I have no idea what I am doing, important due to BS not respecting BEM...
  color: $wfb-green !important;
  background-color: $wfb-light-green;
}

@mixin background-gold-brown() {
  color: $wfb-gold !important;
  background-color: $wfb-brown-gold;
}

@mixin background-milder() {
  //I have no idea what I am doing
  color: $wfb-blue !important;
  background-color: $wfb-light-blue;
}

@mixin background-pink() {
  color: #EB47A7 !important;
  background-color: #FDDBEF;
}
