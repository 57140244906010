.form-control {
  background-color: $wfb-input-bg-light;
  color: $wfb-input-text-dark;
  font-size: rem(14px);
  font-weight: $fw-medium;
  padding: rem(10px) rem(20px);
  border: none;

  &:focus, &:active {
    border-color: $wfb-input-border-dark;
  }

  @include themify($themes) {
    background-color: themed('input-bg-color');
    color: themed('input-color');
  }

  &::placeholder {
    color: $wfb-input-text-dark;
    opacity: 1;
    @include themify($themes) {
      color: themed('input-color');
    }
  }

  &:focus {
    &:not([disabled]) {
      @include theme-dark {
        background-color: $wfb-input-bg-dark-hover;
      }
    }
  }
}
