.dropdown {
  &-menu {
    @include background-base();
  }
  &-item {

  }
  &-divider {
    @include themify($themes) {
      border-color: themed('separator-color');
    }
  }
}