.ftmo-table {
    @include themify($themes) {
        color: themed('color-light');
    }

    border: none;

    thead, tbody {
        > tr {
            border-bottom: 1px solid $border-light-main;

            @include themify($themes) {
                border-bottom: 1px solid themed('border-color');
            }
        }

    }

    td, th {
        padding: 20px;
        &--bordeless {
            border-bottom: none;
        }
    }

    tbody {
        td:nth-child(even) {
            background-color: $wfb-light-gray;
            @include themify($themes) {
                background-color: themed('mild-bg-color');
            }
        }
    }

    &--boxed {
        @include background-base();
    }
}
