.toast {
  @include global-size-modifiers();
  &.toast {
    @include each-bs-color();
  }
  display: flex;
  flex-flow: row;
  padding: rem(14px);
  align-items: center;
  &__box {
    position: relative;
  }
  &__body {
    flex: 1 1 auto;
    text-align: left;
  }
  &__close {
    flex: 0 0 auto;
    align-self: flex-start;
    box-sizing: content-box;
    width: rem(18px);
    height: rem(18px);
    padding: 0;
    color: #000;
    border: 0;
    opacity: 0.5;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image:  svg-filled($svg-icon-close-in-circle, '#000', quote($icon-light)) ;
    @include themify($themes) {
      background-image:  svg-filled($svg-icon-close-in-circle, '#000', quote(themed('icon-color'))) ;
    }
  }
}

.toast-box {
  & .toast {
    &+.toast{
      margin-top: rem(10px);
    }
  }
  &--floating {
    position: fixed;
    top: rem(20px);
    right: rem(20px);
    z-index: $z-index-toast;
    @include media-breakpoint-down(sm) {
      left: rem(20px);
    }
  }
}
