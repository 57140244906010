.form-select {
  cursor: pointer;
  background-color: $wfb-input-bg-light;
  color: $wfb-input-text-dark;
  font-size: rem(14px);
  font-weight: $fw-medium;
  padding: rem(10px) rem(20px);
  border: none;
  background-image: svg-filled($svg-icon-chevron-down, '#000', $wfb-input-text-dark);
  background-size: rem(11px) auto;

  &:focus, &:active {
    border-color: $wfb-input-border-dark;
  }

  @include themify($themes) {
    background-color: themed('input-bg-color');
    color: themed('input-color');
    background-image: svg-filled($svg-icon-chevron-down, '#000', quote(themed('input-color')));
  }

  &:focus {
    &:not([disabled]) {
      @include theme-dark {
        background-color: $wfb-input-bg-dark-hover;
      }
    }
  }
}
